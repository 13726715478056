import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { selectAdmin } from '../../Store/authSlice';

export const PrivateRouteAdmin = ({ element }) => {
  const [dashboard, setDashboard] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const storeAdmin = useSelector(selectAdmin);

  useEffect(() => {
    async function checkuser() {

      try {
        const jwtadmintoken =  localStorage.getItem('admintoken');

        if (!jwtadmintoken) {
          throw new Error('Token not found');
        }


        const response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/verify/${storeAdmin._id}/tokenAuth`, {
          headers: {
            authorization: jwtadmintoken,
          },
        });

        
        if (response.data.message === 'Invalid Token') {

          toast.error('Token is invalid');
          // navigate('/');

        } else if (response.data.message === 'User not found') {
          toast.error('User not found');
          // navigate('/');

        } else if (response.data.message === 'Access denied') {
          toast.error('Access is denied');
          // navigate('/');

        } else if (response.data.message === 'Unauthorized') {
          toast.error('You are Not authorized');
          // navigate('/');

        } else if (response.data.message === 'Access Granted') {
        //   toast('Welcome to your dashboard');
          setDashboard(true);
        }
      } catch (error) {
       
        toast.error('Unable to Load User, try Again Later');
        // navigate('/');
      }


    }
    checkuser()

    // Check if a token is already in localStorage

  }, [id, navigate,storeAdmin ]);


  return dashboard ? element : null;
};

