import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/scss/main.scss'
import style from "./login.module.css"
import { useDispatch } from 'react-redux';
import { login } from '../../Store/authSlice';


export function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [loginData, setloginData] = useState({
    email: '',
    password: '',

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setloginData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  async function loginUser(e) {

    e.preventDefault();
    try {
      setLoading(true)
      const response = await axios.post("https://confused-crow-windbreaker.cyclic.app/api/admin/login", loginData);



      if (response && response.status === 200) {
        setLoading(false)
        toast.success('Successfully Sign In')
        localStorage.setItem('admintoken', response.data.admin.jwtadmintoken);
        dispatch(login(response.data.admin));
        navigate(`/Admin`)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.message)
      } else if (error.response.status === 401) {
        toast.error(error.response.data.message)
      } else if (error.response.status === 500) {
        toast.error(error.response.data.message)
      } else {
        toast.error("Failed to Login")
      }
    }
  }

  return (<>

    <section className="bg-white">
      <div className="container  py-2 h-100">
        <div className="row  d-flex align-items-center justify-content-center h-100">
          <div className="col-md-8  col-lg-7 col-xl-6">
            <img
              width={"100%"}
              height={"100%"}
              src="/login2.png"
              className="img-fluid"
              alt="Phoneimage"
            />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-lg-1">
            <form onSubmit={loginUser}>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form1Example13">
                  Email address
                </label>
                <input
                  required
                  type="email"
                  name='email'
                  onChange={handleInputChange}
                  value={loginData.email}
                  id="form1Example13"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form1Example23">
                  Password
                </label>
                <input
                  required
                  value={loginData.password}
                  onChange={handleInputChange}
                  name='password'
                  type="password"
                  id="form1Example23"
                  className="form-control form-control-lg"
                />
              </div>

              <div className='d-flex mb-1 align-items-center  justify-content-left gap-3 text-center'>
                <div className='text-success '>
                  Create an account?
                </div>
                <Link to={"/SingUp"} className={style.otherroute}>Sing Up</Link>
              </div>


              <button type="submit" className={style.buttonLogin}>
                Sign in
              </button>


            </form>
          </div>
        </div>
      </div>
    </section>

    {loading &&
      <div className={style.backdrop}>
        <div className={style.spinner}>
        </div>

      </div>}

  </>);
}
