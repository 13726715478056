import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/ADSidebar";
import Header from "../Header/ADHeader";
import { Container } from "reactstrap";
import { useState, useEffect } from "react";
import { Loader } from "../Loader/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { addOrderdata} from "../../Store/authSlice";
import style from "./routes.module.css"


export function FullLayoutAdmin(){
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    async function getOrdersData() {
      try {

        setLoading(true)
        const response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/get_orders`)
        setLoading(false)
        if (response && response.status === 200) {

          dispatch(addOrderdata(response.data.allorders))
          toast.success(response.data.message)
        }
      } catch (error) {
        setLoading(false)
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);

        } else {
          console.log("Failed to Load User Data");
        }

      }
    }
    getOrdersData()


  }, [])



  return (
    <main>
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}

        <aside className="sidebarArea shadow" id="sidebarArea">
          {<Sidebar />}
        </aside>

        {/********Content Area**********/}

        <div className="contentArea">
          {/********header**********/}
          <Header />
          {/********Middle Content**********/}
          <div className={style.containerScroll}>
            <Container style={{ padding: 0 }} className="wrapper" fluid>
              <Outlet />
            </Container>
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </main>

  );
};
