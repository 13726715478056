import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import style from "./ui.module.css"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { selecteOrdersData, addOrderdata } from '../../Store/authSlice';
import { Loader } from "../Loader/loader";




export function UserDetailpage() {
    const dispatch = useDispatch();
    const storeOrdersData = useSelector(selecteOrdersData)
    const { id } = useParams()
    let [loading, setloading] = useState(false);
    let [order, setOrder] = useState();
    const [selectedOption, setSelectedOption] = useState(order ? order.orderStatus === true ? "Completed" : "Pendding" : 'Select..');

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [deltedId, setDeletedId] = useState('')

    const handleDropdownChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
    };

    useEffect(() => {

        let CurrentOrder = storeOrdersData.find((userObject) => {
            return userObject._id === id;
        });

        setOrder(CurrentOrder)

    }, [id, storeOrdersData])




    const handelSubmitStatus = async (e) => {
        e.preventDefault()

        if (selectedOption === 'Select..') {
            toast.info("Please Select Any Options")
            return
        }
        try {
            // console.log(selectedOption);
            const orderStatus = selectedOption === 'Completed' ? true : false;
            console.log(order._id, orderStatus);
            setloading(true)
            const response = await axios.post(`https://confused-crow-windbreaker.cyclic.app/api/orders/${order._id}/status`, { orderStatus })
            setloading(false)
            if (response && response.status === 200) {

                dispatch(addOrderdata(response.data.allorders))
                setOrder(response.data.allorders)
                toast.success(response.data.message)
            }
        } catch (error) {
            setloading(false)
            if (error.response.status === 401) {
                toast.error(error.response.data.message);
            } else if (error.response.status === 400) {
                toast.error(error.response.data.message);
            } else if (error.response.status === 500) {
                toast.error(error.response.data.message);

            } else {
                console.log("Failed to Load User Data");
            }

        }
        setSelectedOption('Select..')


    }
    return (<>
        <div className={`p-2  text-light ${style.Sheading} `}>

            <h2 className={style.Heading}>
                Order Detail
            </h2>
        </div>

        {order && <div>
            <div className="p-3">

                <div >
                    <h4 className="text-center " >
                        Order Status
                    </h4>
                    <div className={style.ProfileDIv}>
                        <span className="fw-bold">Change Status</span>
                        <form onSubmit={handelSubmitStatus} className={`${style.dropdownSelect} ${style.ProfileDIv}`} >
                            <select
                                className={style.dropdown}
                                id="userStatus"
                                name="userStatus"
                                value={selectedOption}

                                onChange={handleDropdownChange}

                            >

                                <option value="Select..">Select..</option>
                                <option value="Completed">Completed</option>
                                <option value="Pendding">Pendding</option>
                            </select>
                            <button style={{ marginLeft: "10px" }} type="submit">
                                Update
                            </button>
                        </form>

                    </div>

                    <div className={style.ProfileDIv}>
                        <p>

                            <span className="fw-bold">Status</span>
                        </p>
                        <p>

                            {order.orderStatus === true ? <span className={style.active}>Completed</span> : <span className={style.suspend}>Pendding</span>}
                        </p>

                    </div>

                    <h4 className="text-center " >
                        User Details
                    </h4>
                    {/* <div className={style.ProfileDIv}> */}

                    <p>
                        <span className="fw-bold">Name</span> : <span>{order.firstName + " " + order.lastName}</span>
                    </p>
                    <p>
                        <span className="fw-bold">Contact</span> : <span>{order.countrycode + order.contact2}</span>
                    </p>
                    <p>
                        <span className="fw-bold">Email</span> : <span>{order.Useremail}</span>
                    </p>
                    <p>
                        <span className="fw-bold">City</span> : <span>{order.city}</span>
                    </p>
                    {/* </div> */}

                    <h4 className="text-center " >
                        Dates
                    </h4>
                    {/* <div className={style.ProfileDIv}> */}

                    <p>
                        <span className="fw-bold">Move Date</span> : <span>{order.moveDate}</span>

                    </p>
                    <p>
                        <span className="fw-bold">Move Time</span> : <span>{order.moveTime}</span>

                    </p>
                    {/* </div> */}
                    <h4 className="text-center " >
                        PickUp Details
                    </h4>
                    {/* <div className={style.ProfileDIv}> */}

                    <p>

                        <span className="fw-bold">Moving Item</span> : <span>{order.movingItem}</span>
                    </p>
                    <p>
                        <span className="fw-bold">Pick Up City</span> : <span>{order.PickUpCity}</span>

                    </p>
                    <p>
                        <span className="fw-bold">Pick Up Address</span> : <span>{order.pickUpaddress1 + order.pickUpArea + order.PickUpState}</span>

                    </p>
                    <p>
                        <span className="fw-bold">Pick-Up Buidling Detail</span> : <span>{"Name: " + order.PickUpBuildingdName + " UnitNumber: " + order.PickUpBuildingUnitNumber + " Floor Number: " + order.PickUpBuildingFloorNumber + " Distance From Pickp Point " + order.DistancePickupPoint + order.PickUpBuildingServiceLift ? 'Service Lift Available' : 'No Service Lift'}</span>

                    </p>
                    {/* </div> */}
                    <h4 className="text-center">
                        Delivery Details
                    </h4>
                    {/* <div className={style.ProfileDIv}> */}

                    <p>
                        <span className="fw-bold">Delivery City</span> : <span>{order.DeliveryCity}</span>

                    </p>
                    <p>
                        <span className="fw-bold">Delivery Address</span> : <span>{order.deliveryAddress1 + order.DeliveryArea + order.DeliveryState}</span>

                    </p>
                    <p>
                        <span className="fw-bold">Delivery Buidling Detail</span> : <span>{"Name: " + order.DeliveryBuildingdName + " UnitNumber: " + order.DeliveryBuildingUnitNumber + " Floor Number: " + order.DeliveryBuildingFloorNumber + " Distance From Pickp Point " + order.DistanceDeliveryPoint + order.DeliveryBuildingServiceLift ? 'Service Lift Available' : 'No Service Lift'}</span>

                    </p>
                    <h4 className="text-center">
                        Items Details
                    </h4>
                    <div>


                        {order.cartItems.map((items, index) => {
                            return <div className={style.ProfileGrid} key={index}>
                                <p>
                                    {items.name}
                                </p>
                                <p>
                                    {items.cbm}
                                </p>
                                <p>
                                    {items.Quantity}
                                </p>
                            </div>
                        })}

                    </div>
                    <button onClick={(e)=>{
                        setDeletedId(order._id)
                        setModal(true)
                    }} className={style.ButtonDelte}>Delete Order</button>

                </div>
            </div>


        </div >
        }


        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete  Order</ModalHeader>
            <ModalBody>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Are you sure you want to Delete Order?  This action cannot be undone.
                    </p>

                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={async () => {
                    setModal(!modal)
                    try {

                        setloading(true)
                        let response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/orders/${deltedId}/Delete_order`)
                        setloading(false)
                        if (response && response.status === 200) {
                            toast.success(response.data.message)
                            // console.log(response.data.allorders);
                            setOrder(response.data.allorders)
                            dispatch(addOrderdata(response.data.allorders))
                        }

                    } catch (error) {
                        setloading(false)
                        if (error.response.status === 401) {
                            toast.error(error.response.message);
                        } else if (error.response.status === 400) {
                            toast.error(error.response.message);
                        } else if (error.response.status === 500) {
                            toast.error(error.response.message);

                        } else {
                            toast.error("Failed to Delete Order")
                        }

                    }

                }}>
                    Delete
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

        <Loader loading={loading} />

    </>)
}
