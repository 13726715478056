import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  admin: null,
  OrdersData: [], 
  
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.admin = action.payload ;
    },
    logout: (state) => {
      state.admin = null;
    },
    addOrderdata: (state, action) => {
      state.OrdersData = action.payload; 
    },   
    
   
  },
 
});


// authSlice.js (inside the same file)
export const selecteOrdersData = (state) => state.auth.OrdersData;
export const selectAdmin = (state) => state.auth.admin;


export const { login, logout , addOrderdata} = authSlice.actions;

export default authSlice.reducer;
