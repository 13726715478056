import UsersTable from "./orderPage";
import { Col} from "reactstrap";

export const Users = () => {
  return (
    <div >
      <Col lg="12">       
        <UsersTable/>
      </Col>
    </div>
  
  );
};

