import { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { login, selectAdmin ,   } from './Store/authSlice';
import { AdminDashboard } from './components/Routes/Admin Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { RoutingCallR } from './components/Routing/routingcall';
import { Loader } from './components/Loader/loader';


const App = () => {

  const [loading, setLoading] = useState(false);
  const storeAdmin = useSelector(selectAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate()

 
  useEffect(() => {
    async function checkadmin() {
      // setLoading(true)

      try {
        const jwtadmintoken = localStorage.getItem('admintoken');
        if (!jwtadmintoken) {
          throw new Error('Token not found');
        }
        const response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/session/get_user_fronted_session`, {
          headers: {
            authorization: jwtadmintoken,
          },
        });
        if (response && response.data.message === 'Invalid Token') {
          // setLoading(false)

          await localStorage.removeItem('admintoken');
          // await navigate("/Login")

        } else if ( response && response.data.message === 'Session exist') {
          setLoading(false)
          dispatch(login(response.data.admin));
          navigate("/Admin")
        }
      } catch (error) {
        // navigate("/Login")
        // setLoading(false)
      }
    }
    checkadmin()

  }, []);

  return (<>

    {/* <Router> */}
    <Routes>
      {storeAdmin && <Route exact path='/Admin/*' element={<AdminDashboard />} />}
      <Route exact path='/*' element={<RoutingCallR />} />


    </Routes>
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <Loader loading={loading} />

    {/* </Router> */}

  </>);
}

export default App;
