import { UserDetailpage } from "../Pages/OrderDetailPage";
import { Navigate } from "react-router-dom";
import { Users } from "../Pages/orders"
import { PrivateRouteAdmin } from "./PrivateRouteAdmin";
import {Starter} from "../Home/Starter";
import {FullLayoutAdmin} from "./ADFullLayout"
// import { lazy } from "react";
// const FullLayout = lazy(() => import("./ADFullLayout"));
// const Starter = lazy(() => import("../Home/Starter"));
export const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayoutAdmin />,
    children: [
      { path: "/", element: <Navigate to="AdminDashboard/starter" /> },
      { path: "AdminDashboard/Users", exact: true, element: <Users /> },
      { path: "AdminDashboard/UserDetails/:id", exact: true, element: <PrivateRouteAdmin element={<UserDetailpage />} /> },
        { path: "AdminDashboard/starter", exact: true, element: <PrivateRouteAdmin element={<Starter />} /> },

    ],
  },
];


