import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import TopCards from "../Cards/TopCards";
import style from "../Pages/ui.module.css"
import { useSelector } from "react-redux";
import { selecteOrdersData } from '../../Store/authSlice';


export function Starter  () {
  const storeOrdersData = useSelector(selecteOrdersData)
  const [completed, setcompleted] = useState()

  useEffect(() => {
    let completedOrders = 0;
    storeOrdersData.forEach((userobjects) => {
      if (userobjects.orderStatus === true) {
        completedOrders++;
      }
    })
    setcompleted(completedOrders)
  }, [])

  return (<>


    <div className={`p-2  text-light ${style.Sheading} `}>

      <h2 className={style.Heading}>
        Reports
      </h2>
    </div>
    <div className="p-3">
      <div className={`${style.mainDashboard} `}>
        <h2>
          Satatistics
        </h2>
        <div >
          <div>
            {storeOrdersData && <>
              <div>
                <p className="fw-bold ">
                  Total Orders
                </p>
              </div>
              <Row>
                <Col sm="6" lg="4">
                  <TopCards
                    bg=" bg-light-info text-info"
                    title="Profit"
                    subtitle="Total Orders"
                    earning={storeOrdersData.length}
                    icon="bi bi-people"
                  />
                </Col>


                <Col sm="6" lg="4">
                  <TopCards
                    bg="bg-light-success text-success"
                    title="Sales"
                    subtitle="Completed Orders"
                    earning={completed}
                    icon="bi bi-person-check"
                  />
                </Col>
                <Col sm="6" lg="4">
                  <TopCards
                    bg="bg-light-danger text-danger"
                    title="Refunds"
                    subtitle="Pennding Orders"
                    earning={+(storeOrdersData.length) - completed}
                    icon="bi bi-person-dash"
                  />
                </Col>
              </Row>
            </>}

          </div>
        </div>
      </div>
    </div>


  </>);
};

