import { Outlet } from "react-router-dom";

export const FulllayoutMain = () => {

    return (
        <main>
            <Outlet />
        </main>
    );
};

