import {
  CardBody, CardTitle, CardSubtitle, Table, Button
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, InputGroup } from 'reactstrap';
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import React from "react";
import 'react-dropdown/style.css';
import style from "./ui.module.css"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selecteOrdersData, addOrderdata } from "../../Store/authSlice";
import { useDispatch } from 'react-redux';
import { Loader } from "../Loader/loader";


const ProjectTables = () => {

  const [valuechange, setvalueChange] = useState()
  const dispatch = useDispatch();
  const storeOdersData = useSelector(selecteOrdersData)

  let [loading, setloading] = useState(false);
  const [deltedId, setDeletedId] = useState()

  const [currentorderData, setcurrentOrder] = useState();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);



  useEffect(() => {

    async function getOrdes() {
      try {

        setloading(true)
        const response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/get_orders`)

        if (response && response.status === 200) {
          setloading(false)
          // console.log(response.data.allorders);
          dispatch(addOrderdata(response.data.allorders))
          setcurrentOrder(response.data.allorders)
          toast.success(response.data.message)


        }
      } catch (error) {
        setloading(false)
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);

        } else {
          console.log("Failed to Get Orders ");
        }

      }
    }
    getOrdes()


  }, [valuechange, dispatch])





  return (<div >

    {currentorderData && <div>
      <div className={`p-2  text-light ${style.Sheading} `}>

        <h2 className={style.Heading}>
          Orders Details
        </h2>
      </div>

      <div>
        <CardBody>
          <CardTitle tag="h5">  Orders Listing</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Overview of the All Users Orders
          </CardSubtitle>

          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Move Time</th>
                <th>Move Date</th>
                <th>Status</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {currentorderData.map((tdata, index) => (
                <tr key={index} className="border-top">
                  <td>
                    <Link to={`/Admin/AdminDashboard/UserDetails/${tdata._id}`} className={`${style.userProfile} d-flex align-items-center text-dark pointer p-2`}>
                      <h6 className="mb-0">{tdata.firstName + " " + tdata.lastName}</h6>
                    </Link>
                  </td>
                  <td>{tdata.Useremail}</td>
                  <td>{tdata.countrycode + " " + tdata.contact2}</td>
                  <td>{tdata.moveTime}</td>
                  <td>{tdata.moveDate}</td>
                  <td>
                    {tdata.orderStatus === true ? <button className={style.active}>Completed</button> : <button className={style.suspend}>Pendding</button>}
                  </td>


                  <td>
                    <Button className="Reject"
                      onClick={() => {
                        setDeletedId(tdata._id)
                        setModal(!modal);
                      }}
                    ><i className="bi bi-trash3"></i></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

        </CardBody>
      </div>
    </div>}

    <div style={{position:"absolute" , zIndex:550000}}>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete  Order</ModalHeader>
        <ModalBody>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to Delete Order? This action cannot be undone.
            </p>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={async () => {
            setModal(!modal)
            try {

              setloading(true)
              let response = await axios.get(`https://confused-crow-windbreaker.cyclic.app/api/orders/${deltedId}/Delete_order`)

              if (response && response.status === 200) {
                setloading(false)
                toast.success(response.data.message)
                dispatch(addOrderdata(response.data.allorders))
                setcurrentOrder(response.data.allorders)

              }

            } catch (error) {
              setloading(false)
              if (error.response.status === 401) {
                toast.error(error.response.message);
              } else if (error.response.status === 400) {
                toast.error(error.response.message);
              } else if (error.response.status === 500) {
                toast.error(error.response.message);

              } else {

                toast.error("Faild to Delete User")
              }

            }


            setvalueChange(2)
          }}>
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Loader loading={loading} />
    </div>
  </div>);
};

export default ProjectTables;

